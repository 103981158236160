import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export default {
  setup(app: App<Element>, router: Router): void {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          networkDetailAllowUrls: [/^https:\/\/api\.hearseemobility\.org\/mapping\/maps/],
        }),
        Sentry.httpClientIntegration(),
        Sentry.replayCanvasIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', import.meta.env.VITE_HEARSEE_API],
      // Session Replay
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,
      trackComponents: true,
      timeout: 500,
      environment: import.meta.env.MODE,
    })
  },
}
