import { auth0 } from '@/auth0'
import { AuthenticationError } from '@/models/errors/AuthenticationError'

class AuthenticationService {
  public static async getToken(): Promise<string> {
    const { getAccessTokenSilently } = auth0

    try {
      return await getAccessTokenSilently()
    } catch {
      return this.getTokenWithPopup()
    }
  }

  public static async login(): Promise<void> {
    const { loginWithRedirect } = auth0

    loginWithRedirect()
  }

  public static async logout(): Promise<void> {
    const { logout: authLogout } = auth0

    authLogout({ logoutParams: { returnTo: window.location.origin } })
  }

  private static async getTokenWithPopup(): Promise<string> {
    const { getAccessTokenWithPopup } = auth0

    try {
      const token = await getAccessTokenWithPopup()

      if (!token) {
        throw new AuthenticationError()
      }

      return token
    } catch {
      throw new AuthenticationError()
    }
  }
}

export default AuthenticationService
