<script setup lang="ts">
import { computed } from 'vue'

import AppButton from '@/components/atomic/AppButton/AppButton.vue'
import { ButtonSize } from '@/models/Button'

export interface Props {
  isAuthenticated: boolean
}

const emit = defineEmits<{
  (login: 'login'): void
  (logout: 'logout'): void
}>()

const props = defineProps<Props>()

const buttonLabel = computed<string>(() => (props.isAuthenticated ? 'Log Out' : 'Log In'))

function onClick(): void {
  return props.isAuthenticated ? emit('logout') : emit('login')
}
</script>

<template>
  <div>
    <AppButton
      :size="ButtonSize.SMALL"
      @click="onClick"
    >
      {{ buttonLabel }}
    </AppButton>
  </div>
</template>
